<template>
  <div>
    <div class="card p-3 shadow-sm">
      <form @submit.prevent="">
        <div class="row justify-content-between">
          <div class="col-auto">
            <h5 class="e-text-red">
              Inspection
            </h5>
          </div>
          <div class="col-auto"
               v-if="isAllowed('psc.main.inspection.details.edit') && isOwnVessel('pscReport')">
            <template v-if="toEdit">
              <button class="e-btn e-btn-outlined-red ml-2" type="button" @click="cancelUpdate" v-if="toEdit">Cancel</button>
              <button class="e-btn e-btn-blue ml-2" :class="hasChanges === false ? 'e-btn-blue-disabled' : ''" type="button" @click="updatePsc" :disabled="hasChanges === false">Save</button>
            </template>
            <template v-else>
              <button class="e-btn e-btn-green ml-2 px-3" type="button" @click="editMainReport">Edit Inspection Details</button>
            </template>
          </div>
        </div>
        <table class="w-100">
          <tr>
            <td style="width: 25%">Name of Inspector <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td>
              <input type="text" v-if="toEdit" v-model="mainReport.inspection.inspector_name" class="form-control-sm form-control" name="inspection_inspector_name" id="inspection_inspector_name">
              <span v-else>{{ pscGet('inspection.inspector_name') | toUpper }}</span>
            </td>
          </tr>
          <tr>
            <td>Deficiencies <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td>
              <template v-if="!!mainReport.inspection && toEdit">
                <select class="form-control form-control-sm" v-model="mainReport.inspection.deficiencies" style="width: 150px;">
                  <option :value="true">YES</option>
                  <option :disabled="pscGet('psc_deficiencies').length > 0" :value="false">NO</option>
                </select>
              </template>
              <span v-else>{{pscGet('inspection.deficiencies') ? 'YES' : 'NO'}}</span>
            </td>
          </tr>
          <template v-if="!!mainReport.inspection">
            <tr v-if="!mainReport.inspection.deficiencies && toEdit">
              <td>Psc Bonus (USD)</td>
              <td>
                <input type="number" v-model="mainReport.inspection.bounty"
                       class="form-control form-control-sm" name="bounty"
                       id="bounty" step="0.01" min="0.00" style="width: 150px;">
              </td>
            </tr>
          </template>
          <template v-else>
            <tr v-if="!pscGet('inspection.deficiencies') && !toEdit">
              <td>Psc Bonus (USD)</td>
              <td>
                <span>{{ pscGet('inspection.bounty')}}</span>
              </td>
            </tr>
          </template>
          <tr>
            <td style="width: 25%">Detained <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td>
              <select v-if="toEdit" class="form-control form-control-sm" v-model="mainReport.inspection.detained" style="width: 150px;">
                <option :value="true">YES</option>
                <option :value="false">NO</option>
              </select>
              <span v-else>{{ pscGet('inspection.detained') | toYesOrNo | toUpper }}</span>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">Number of Deficiencies</td>
            <td>
              <span>{{ pscGet('psc_deficiencies').length }}</span>
            </td>
          </tr>
          <tr>
            <td style="width: 25%" class="align-top">Inspection Outline <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td>
              <vue-editor v-if="toEdit" v-model="mainReport.inspection.outline" :editor-toolbar="toolbar" ></vue-editor>
              <div v-else id="textArea" class="pre-wrap-txt textarea-display-card my-2" v-html="pscGet('inspection.outline')"/>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">Attached PSC Report <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td>
              <template>
                <template>
                  <button class="e-btn e-btn-blue"
                          @click="downloadAll(1,pscReport,'inspection.id')"
                          v-if="isAllowed('psc.main.inspection.attachments.download-all') && isOwnVessel('pscReport') && pscAttachments.length"
                  >
                    Download All
                  </button>
                </template>
              </template>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <attachments-handler
                ref="psc-attachments-handler"
                :current-attachments="allAttachments"
                :edit-mode="toEdit"
                @attachment-has-changes="setChangeValue"
                :changes_id="'psc_attachments'"
                :viewing-permission="isAllowed('psc.main.inspection.attachments.view-download') && isOwnVessel('pscReport')"
                :deleting-permission="isAllowed('psc.main.inspection.attachments.delete') && isOwnVessel('pscReport')"
              />
            </td>
          </tr>
        </table>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ObjectMixin from "../../../mixins/ObjectMixin";
import {AlertService} from "../../../services/AlertService";
import AttachmentMixin from "../../../mixins/attachmentMixin";
import {permissionMixin} from "@/mixins/permissionMixins";
import PscAttachment from "./PscAttachment.vue";
import {DataService} from "@/services/DataService";
import AttachmentsHandler from "@/components/common/AttachmentsHandler.vue";

export default {
  name: "PscInspection",
  data() {
    return {
      toEdit: false,
      toolbar:[
        ['bold','italic','strike','underline'],
        [{'color':["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]}]
      ],
      default: {
        mainReport: {
          id: parseInt(this.$route.params.id),
          inspection: {
            inspector_name: null,
            deficiencies: false,
            detained: false,
            deficiency_count: 0,
            outline: null,
            bounty:0,
          },
        },
      },
      mainReport: {},
      rankIdMaster: 1,
      rankIdCE: 2,
      crewLists: {},
      addAttachment:false,
      changeValues : {
        psc_attachments : false
      }
    }
  },
  mixins: [ObjectMixin,AttachmentMixin,permissionMixin],
  components:{AttachmentsHandler, PscAttachment},
  methods: {
    ...mapActions(['updatePscInfo','uploadPscAttachments']),
    async cancelUpdate(){
      if (this.hasChanges === false) {
        this.toEdit = false
        this.mainReport = this.default.mainReport;
      } else {
        if (await AlertService.cancelAlert()) {
          await this.resetFields()
        }
      }
    },
    async updatePsc() {
      if (await AlertService.confirmUpdateAlert("Do you want to update ? ")) {
        if (this.$refs["psc-attachments-handler"].isProcessingFiles() === true) {
          return AlertService.warningAlert(`Please wait for the documents to finish processing`, 'ATTACHMENTS PROCESSING');
        }

        const response = await this.updatePscInfo(this.mainReport);
        await this.requestUploadNewAttachments();
        await this.requestDeleteAttachments();
        if (response) {
          AlertService.successAlert('PSC updated successfully', 'UPDATE PSC')
          await this.resetFields()
        }
      }
    },
    editMainReport() {
      this.toEdit = true;
      this.mainReport = {
        id: this.pscGet('id'),
        inspection: {
          inspector_name: this.pscGet('inspection.inspector_name'),
          deficiencies: !!this.pscGet('inspection.deficiencies'),
          detained: !!this.pscGet('inspection.detained'),
          deficiency_count: this.pscGet('inspection.deficiency_count'),
          outline: this.pscGet('inspection.outline'),
          bounty: this.pscGet('inspection.bounty'),
        }
      }
    },
    async resetFields() {
      this.toEdit = false
      this.mainReport = this.default.mainReport;
      await this.getAttachments(1, this.pscReport,'inspection.id')
      this.changeValues.psc_attachments = false;
      this.$refs["psc-attachments-handler"].resetValues();
    },
    async requestUploadNewAttachments() {
      const pscAttachments = this.$refs["psc-attachments-handler"].getValidFilesUniqueIds();
      let formData=new FormData();
      for (let counter = 0; counter < pscAttachments.length; counter++) {
        formData.append('file_unique_ids[]',pscAttachments[counter])
      }

      formData.append('id',this.pscGet('id'))
      formData.append('type[id]',this.pscGet('inspection.id'))
      formData.append('type[type_id]',1)
      const response =await this.uploadPscAttachments(formData);
      if(response){
        this.addAttachment=false;
      } else {
        AlertService.errorAlert('There\s a problem adding attachments','Add Inspection Attachments')
      }
    },
    async requestDeleteAttachments() {
      const filesToRemove = this.$refs["psc-attachments-handler"].getFilesToRemove();
      for (let counter = 0; counter < filesToRemove.length; counter++) {
        await this.deletePscAttachment({
          id : this.$route.params.id,
          type : {
            id : filesToRemove[counter].id,
            type_id : filesToRemove[counter].attachment_type
          }
        });
      }
    },
    setChangeValue(changeId, value) {
      this.changeValues[changeId] = value;
    }

  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasChanges === true) {
      if (await AlertService.cancelChanges()) {
        next();
      } else {
        next(false);
      }

    } else {
      next();
    }
  },
  computed: {
    ...mapGetters([
      'pscReport',
      'pscAttachments'
    ]),
    allAttachments() {
      return this.pscAttachments.map(document => {
        return {
          source : document.id,
          options : {
            type : 'local',
            file : {
              name : document.name,
              type : document.mime_type,
              size: parseFloat(document.file_size) * 1024
            },
            metadata : {
              id : document.id,
              thumbnail : document.thumbnail,
              file_size : document.file_size,
              created_at : document.created_at,
              extension : document.extension,
              attachment_type : 1,
              view_attachment_method : () => this.viewDownloadFile(document,1)
            }
          }
        }
      });
    },
    hasChanges() {
      if (this.toEdit === false) {
        return false;
      }

      let originalData = {
        inspector_name : this.pscReport.inspection.inspector_name,
        deficiencies   : this.pscReport.inspection.deficiencies,
        bounty         : this.pscReport.inspection.bounty,
        detained       : this.pscReport.inspection.detained,
        outline        : DataService.normalizeWhitespace(DataService.stripHtml(this.pscReport.inspection.outline)),
      }

      let editedData = {
        inspector_name : this.mainReport.inspection.inspector_name === '' ? null : this.mainReport.inspection.inspector_name,
        deficiencies   : this.mainReport.inspection.deficiencies,
        bounty         : this.mainReport.inspection.bounty === '' ? null : this.mainReport.inspection.bounty,
        detained       : this.mainReport.inspection.detained,
        outline        : DataService.normalizeWhitespace(DataService.stripHtml(this.mainReport.inspection.outline)),
      };

      return this.changeValues.psc_attachments === true || DataService.checkIfChanged(originalData, editedData);
    }
  },
  watch : {
    toEdit () {
      this.$store.commit('SET_TO_EDIT', this.toEdit);
    }
  }

}
</script>

<style scoped>
table td {
  padding: 2px !important;
}
::v-deep .pre-wrap-txt.textarea-display-card p {
  margin-bottom: 0 !important;
}
</style>
